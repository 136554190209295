var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"track-view",rawName:"v-track-view",value:(_vm.sectionId),expression:"sectionId"}],staticClass:"featured fc-padding-4"},[_c('div',{staticClass:"featured__logos md:fc-margin-y-0 md:fc-margin-x-8 fc-flex fc-justify-around fc-align-center"},_vm._l((_vm.getLogoLists),function(logo,index){return _c('div',{key:logo._uid,staticClass:"featured__logo",class:_vm.hideLogo(index)},[_c('FCImage',{attrs:{"fc-provider":"storyblok","fc-modifiers":{
          isStatic: true,
        },"nuxt-image-props":Object.assign({}, _vm.getPictureResponsiveProps(logo),
          {sizes: 'xs:200px',
          loading: 'eager',
          preload: true,
          width: 104,
          height: 46})}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }