


























import { computed, defineComponent } from '@vue/composition-api';
import { transformPictureResponsiveContent as getPictureResponsiveProps } from '~/selectors/selectors';
import FCImage from '~/components/FCImage.vue';

export default defineComponent({
  name: 'Featured',
  inheritAttrs: false,
  components: {
    FCImage,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    return {
      getLogoLists: computed(() => props.content.contentContainer),
      hideLogo(index: number) {
        return index >= 4 ? 'sm:fc-hidden' : '';
      },
      getPictureResponsiveProps,
    };
  },
});
